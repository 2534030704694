import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';
import Spinner from '~/assets/icons/spinner';

export interface ButtonNewProps
  extends Pick<
    ComponentPropsWithoutRef<'button'>,
    'disabled' | 'className' | 'onClick' | 'type' | 'style' | 'name' | 'value'
  > {
  themeType?: 'primary' | 'link' | 'shade' | 'danger';
  loading?: boolean;
  customDisabledClassName?: string;
  size?: 'small' | 'default';
  children?: React.ReactNode;
}

const ButtonNew: FC<ButtonNewProps> = ({
  children,
  themeType = 'primary',
  loading,
  disabled,
  className,
  size = 'default',
  customDisabledClassName,
  ...generalButtonProps
}) => {
  let themeStyles = [
    'bg-primary px-8',
    'text-darkMode-gray-dark',
    !disabled && 'active:bg-primary/80',
  ];
  if (themeType === 'link') {
    themeStyles = [
      'bg-transparent',
      'text-primary',
      'px-0',
      'py-[18px] rounded-none',
      'active:opacity-60',
    ];
  } else if (themeType === 'shade') {
    themeStyles = [
      'bg-primary/20 text-primary px-8',
      !disabled && 'active:opacity-70',
    ];
  } else if (themeType === 'danger') {
    themeStyles = [
      'bg-darkMode-danger text-darkMode-gray-dark px-8',
      !disabled && 'active:opacity-60 ',
    ];
  }
  let sizeStyles = '';
  if (size === 'small') {
    sizeStyles = 'text-[15px] h-10 py-0 px-5 sticky bottom-6 block ml-auto';
  }

  return (
    <button
      {...generalButtonProps}
      disabled={loading || disabled}
      className={clsx(
        'flex items-center rounded-full py-3 text-xl font-bold leading-6',
        disabled && 'cursor-not-allowed select-none opacity-25',
        themeStyles,
        className,
        sizeStyles,
        disabled && customDisabledClassName
      )}
    >
      {loading ? <Spinner /> : <>{children}</>}
    </button>
  );
};

export default ButtonNew;
